import './App.css';

import React from 'react';
import Routes from './routes';

function App(props) {
  return <Routes />;
}

export default App;
