import styled from 'styled-components';

export default styled.button`
  height: 45px;
  width: 100%;
  background-color: var(--button-color);
  border: none;
  border-radius: 4px;
  width: 500px;
  margin: 8px;

  @media (max-width: 500px) {
    width: 83%;
    height: 35px;
  }
`;
