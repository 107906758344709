import styled from 'styled-components';

export default styled.img.attrs(props => ({
  src: require('../../assets/logo.jpg').default,
}))`
  width: ${props => (props.fullLogo ? '250px' : '50%')};
  height: ${props => (props.fullLogo ? '250px' : '50%')};

  @media (max-width: 500px) {
    width: ${props => (props.fullLog ? '60%' : '50%')};
    height: ${props => (props.fullLogo ? '30%' : '20%')};
  }
`;
