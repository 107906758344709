import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
 * {
    margin: 0;
    padding: 0;
  }

  html, body, #root {
    height: 100%;
    color: var(--white);
  }

  *, button, input {
    border: 0;
    outline: 0;

    font-family: 'Open Sans', sans-serif;
  }

  :root {
    --black: #000;
    --input-height: 45px;
    --button-color: var(--white);
    --white: #fff;
    --square: 500px;
  }
`;
