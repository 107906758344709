import styled from 'styled-components';

export default styled.div`
  width: var(--square);
  height: var(--square);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`;
