import Image from '../components/image';
import React from 'react';

function Home() {
  return (
    <div className="App">
      <Image fullLogo />
    </div>
  );
}

export default Home;
