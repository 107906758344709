import styled from 'styled-components';

export default styled.input`
  ::placeholder {
    color: ${props => (props.errorMessage ? 'red' : 'white')};
  }

  background-color: var(--background-color);

  border: ${props =>
    props.errorMessage ? '1px solid red' : '1px solid white'};
  height: 45px;
  width: 100%;
  border-radius: 4px;
  color: var(--white);
  padding-left: 8px;
  width: 490px;
  margin: 8px;

  @media (max-width: 500px) {
    width: 80%;
    height: 35px;
  }
`;
