import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import ChangePassword from './pages/change-password';
import Home from './pages/home';
import React from 'react';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/:verifyToken">
          <ChangePassword />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
