import React, { useState } from 'react';

import Button from '../components/button';
import Image from '../components/image';
import Input from '../components/input';
import Wrapper from '../components/wrapper';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function ChangePassword(_) {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [successText, setSuccessText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let { verifyToken } = useParams();
  verifyToken = verifyToken.replaceAll('-POINT-', '.');

  function resetSenha() {
    setErrorMessage();

    if (!password || !newPassword) {
      setErrorMessage('Preencha todos os campos.');
    } else if (password !== newPassword) {
      setErrorMessage('As senhas não batem.');
    } else {
      setIsLoading(true);

      axios
        .post(
          process.env.REACT_APP_API_URL + '/auth/recovery',
          { password },
          { headers: { Authorization: `Bearer ${verifyToken}` } },
        )
        .then(response => {
          setSuccessText(response.data.message[0] ?? '');
          setIsLoading(false);
        })
        .catch(response => {
          setErrorMessage(response?.data || 'Erro ao alterar a sua senha!');
        });

      setIsLoading(false);
    }
  }

  return (
    <div className="App">
      <Wrapper>
        <Image />
        {!successText && (
          <>
            <Input
              placeholder="Nova senha"
              onChange={event => setPassword(event.target.value)}
              type="password"
              errorMessage={!!errorMessage}
            />
            <Input
              placeholder="Confirmar nova senha"
              onChange={event => setNewPassword(event.target.value)}
              type="password"
              errorMessage={!!errorMessage}
            />
            <Button onClick={resetSenha}>
              {isLoading ? 'Carregando...' : 'Enviar'}
            </Button>
          </>
        )}
        {!!successText && <h1>{successText}</h1>}
        <p>{errorMessage}</p>
      </Wrapper>
    </div>
  );
}

export default ChangePassword;
